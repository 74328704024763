@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;700;800;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One:wght@400;500;700;800;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700;800;900&display=fallback");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Plus Jakarta Sans";
    src: url("./fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
    /* Add additional font formats if available */
  }
}

.h1 {
  @apply text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-3xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl font-bold leading-snug tracking-tight;
}

.btn,
.btn-sm {
  @apply inline-flex items-center justify-center font-medium leading-snug border border-transparent rounded transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3;
}

.btn-sm {
  @apply px-4 py-2;
}

p strong {
  color: #000;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-white border border-gray-300 focus:border-gray-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply px-4 py-3;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-gray-800 rounded-sm;
}

.button[disabled="disabled"],
.button[disabled] {
  opacity: 0.5;
  pointer-events: none;
  @apply bg-gray-400;
}

button:disabled {
  cursor: default !important;
}

.form-input[disabled="disabled"],
.form-input[disabled] {
  opacity: 0.5;
  pointer-events: none;
  @apply bg-gray-200;
}

input[type="range"] {
  appearance: none;
  background: #ccc;
  @apply bg-gray-400;
  pointer-events: none;
  margin-top: 15px;
  margin-bottom: 15px;
  --thumb-size: 36px;
}
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;

  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-moz-range-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-ms-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}

.form-switch {
  width: 68px;
  @apply relative select-none;
}
.form-switch label {
  height: 38px;
  @apply block overflow-hidden rounded cursor-pointer;
}
.form-switch label > span:first-child {
  @apply absolute block rounded shadow;
  width: 30px;
  height: 30px;
  top: 4px;
  left: 4px;
  right: 50%;
  transition: all 0.15s ease-out;
}

.form-switch input[type="checkbox"]:checked + label {
  @apply bg-blue-600;
}
.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 34px;
}
html {
  scroll-behavior: smooth;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
}
:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  outline: 2px solid rgba(0, 112, 244, 0.5);
}
button:focus,
.btn:focus,
a:focus {
  outline: none;
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  box-shadow: none !important;
}
.hamburger svg > *:nth-child(1),
.hamburger svg > *:nth-child(2),
.hamburger svg > *:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg);
}
.hamburger svg > *:nth-child(1) {
  transition: y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}
.hamburger svg > *:nth-child(2) {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger svg > *:nth-child(3) {
  transition: y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    width 0.1s 0.25s ease-in;
}
.hamburger.active svg > *:nth-child(1) {
  opacity: 0;
  y: 11;
  transform: rotate(225deg);
  transition: y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.1s 0.12s ease-out;
}
.hamburger.active svg > *:nth-child(2) {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger.active svg > *:nth-child(3) {
  y: 11;
  transform: rotate(135deg);
  transition: y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.1s ease-out;
}
.blur {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
@keyframes pulseLoop {
  0% {
    opacity: 0.15;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.15;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
    transform: scale(1.8) translateZ(0);
  }
}
@keyframes pulseMiniLoop {
  0% {
    opacity: 0;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0;
    transform: scale(3) translateZ(0);
  }
}
.pulse {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 10000ms linear infinite;
}
.pulse-mini {
  animation: pulseMiniLoop 6000ms linear infinite;
}
.pulse-1 {
  animation-delay: -3000ms;
}
.pulse-2 {
  animation-delay: -6000ms;
}
.animation-delay-500 {
  animation-delay: 500ms !important;
}
.animation-delay-1000 {
  animation-delay: 1000ms !important;
}
.translate-z-0 {
  transform: translateZ(0);
}
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}

@tailwind utilities;

@keyframes loadingCircle {
  to {
    transform: rotate(360deg);
  }
}

.loading-spin {
  transition: transform 0.3s ease-in-out;
  animation: loadingCircle 1s infinite linear;
}

.terms p {
  margin-bottom: 20px;
}

.terms ol,
.terms ol ol {
  list-style: auto;
  padding-left: 20px;
  margin-bottom: 20px;
}

$aos-distance: 10px;

.switch {
  display: flex;
  justify-content: center;
  padding-top: 150px;
}
.switch__wrapper {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 36px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 34px;
}
.switch__wrapper input {
  display: none;
}
.switch__wrapper input:checked + .switch__slider:before {
  transform: translateX(32px);
}
.switch__wrapper input:checked ~ div:after {
  color: #ffffff;
  transition: color 0.3s ease 0s;
}
.switch__wrapper input:checked ~ div:before {
  color: #919191;
  transition: color 0.3s ease 0s;
}
.switch div::before {
  content: "ID";
  position: relative;
  z-index: 5;
  font-size: 14px;
  color: #ffffff;
  bottom: -4px;
  left: 10px;
  cursor: pointer;
  transition: color 0.3s ease 0s;
}
.switch div::after {
  content: "EN";
  position: relative;
  z-index: 5;
  font-size: 14px;
  color: #919191;
  bottom: -4px;
  left: 27px;
  cursor: pointer;
  transition: color 0.3s ease 0s;
}
.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 34px;
}
.switch__slider::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  bottom: -1px;
  @apply bg-purple-700;
  transition: all 0.4s ease 0s;
  border-radius: 50%;
}

@media print {
  @page {
    size: 8.25in 6.5in;
    margin: 0;
  }

  .print-area-cert {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hide-print {
    display: none;
  }
}

.hero-video {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 480px) {
  .hero-video {
    width: 18rem;
    height: 8rem;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 1099px) {
  .hero-video {
    width: 28rem;
    height: 18rem;
    margin-top: -6rem;
  }
}

@media screen and (min-width: 481px) {
  .card-max-width {
    max-width: 360px;
  }
}

@media screen and (min-width: 1100px) {
  .hero-video {
    width: 42rem;
    height: 25rem;
    margin-top: 4rem;
    margin-right: 4rem;
  }
}

.centered-v-h {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  background-color: rgba(241, 243, 246, 1);
  border-radius: 0.5rem;
  display: inline-block;
  color: #43bccd;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  list-style: none;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  /* border-color: #673CC6;
  color: black;
  border-bottom: 2px solid #673CC6; */
  background-color: rgba(67, 188, 205, 1);
  border-radius: 0.5rem;
  display: inline-block;
  color: rgba(255, 255, 255, 1);
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  list-style: none;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  /*background: #fff;*/
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.carousel-multi .react-multiple-carousel__arrow,
.carousel-multi .react-multiple-carousel__arrow:disabled {
  background: rgba(127, 17, 224, 0.7);
}

.carousel-multi {
  padding-left: 48px;
  padding-right: 48px;
}
.carousel-multi .react-multiple-carousel__arrow--left {
  left: 0;
}

.carousel-multi .react-multiple-carousel__arrow--right {
  right: 0;
}

input.input-password::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input.input-password::-webkit-caps-lock-indicator {
  visibility: hidden;
  display: none !important;
}

input.input-password::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
}

.bg-compare {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(to bottom right, #673cc6 50%, transparent 50.5%)
      no-repeat bottom,
    /* bottom part */ linear-gradient(0deg, #673cc6, #673cc6) no-repeat top;
  /* top portion */
  color: white;
  background-size: 100% 20em, 100% calc(100% - 20em);
}

.bg-compare-univ {
  width: 100%;
  min-height: 8vh;
  background: linear-gradient(to bottom right, #04c4b2 50%, transparent 50.5%)
      no-repeat bottom,
    /* bottom part */ linear-gradient(0deg, #04c4b2, #04c4b2) no-repeat top;
  /* top portion */
  color: white;
  background-size: 100% 8vh, 100% calc(100% - 8vh);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

#phoneVerified {
  align-items: center;
  padding: 12px 16px;

  background: #00a877;
  box-shadow: 0px 4px 12px rgba(93, 15, 250, 0.24);
  border-radius: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

#phoneNotVerified {
  align-items: center;
  padding: 12px 16px;

  background: #fe5244;
  box-shadow: 0px 4px 12px rgba(93, 15, 250, 0.24);
  border-radius: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.hidden-content-container {
  display: none;
}

.hidden-content-container::after {
  display: block;
  content: "This content is only visible in the inspector.";
  /* Add additional styles as needed */
}

div:focus {
  outline: none;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.curved-line {
  position: relative;
  overflow: hidden;
}

.line {
  width: 100%;
  height: 10px;
  background-color: gold;
  position: absolute;
  top: 50%;
}

.curve {
  width: 100%;
  background-color: blue;
  border-radius: 50%;
}

@import "aos/dist/aos.css";
